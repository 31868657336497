<template>
  <div class="registerBox">
    <auth-logo />

    <van-tabs @click="onClick" v-model="active" class="loginTabs" animated>
      <van-tab :title="$t.auth.login"></van-tab>
      <van-tab :title="$t.auth.register"></van-tab>
    </van-tabs>

    <!-- 表单 -->
    <div class="formBox">
      <div class="inputBox">
        <input
          class="username"
          v-model="auth.email"
          type="text"
          :placeholder="$t.auth.placeholderEmail"
        />
      </div>
      <div class="inputBox">
        <input
          class="password"
          v-model="auth.code"
          type="text"
          :placeholder="$t.auth.placeholderEmailCode"
        />
        <button class="getCode" v-if="isShowCode" @click="verifyEmail">
          {{ $t.auth.sendAuthCode }}
        </button>
        <div class="codeInfo" v-else>{{ timerNum }}S</div>
      </div>
      <div class="next" @click="next">{{ $t.auth.next }}</div>
      <div class="classHome">
        <div class="termsTitle">
          <van-checkbox
            v-model="checked"
            checked-color="#01aaa3"
            icon-size="20px"
          ></van-checkbox>
          <div @click="goTerms" class="termsText">
            {{ $t.auth.rulesAndProtocols }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthLogo from "./components/AuthLogo";
import { Tab, Tabs, Checkbox } from "vant";
import Vue from "vue";

Vue.use(Tab).use(Tabs).use(Checkbox);
export default {
  name: "Register",
  components: {
    AuthLogo,
  },
  data() {
    return {
      active: 1,
      checked: false,
      isShowCode: true, //实收显示获取验证码
      timerNum: 0,
      timerItem: null, //定时器对象
      auth: {
        code: "",
        email: "",
      },
    };
  },
  mounted() {
    const { InviteCode } = this.$route.query;
    if (InviteCode) {
      this.inviteCode = InviteCode;
    }
  },
  methods: {
    onClick(index) {
      if (index == 0) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "auth/login",
          name: "Login",
          query: [],
        });
      }
    },
    goTerms() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "terms",
        name: "Terms",
        query: [
          {
            name: "Path",
            value: "register",
          },
        ],
      });
    },
    //验证email并发送验证码
    verifyEmail() {
      const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      const email = this.auth.email;
      if (reg.test(email)) {
        this.$commonMethod.showLoading();
        let param = {
          Email: email,
        };
        this.$api.system
          .sendEmailCode(param)
          .then((res) => {
            this.$commonMethod.hideLoading();
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: this.$t.auth.verificationCodeInfo,
                type: "success",
              }
            );
            //启动定时器
            this.timerTime();
          })
          .catch((err) => {
            this.$commonMethod.hideLoading();
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: err.message,
                type: "danger",
              }
            );
          });
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          titleMsg: this.$t.auth.pleaseEnterEmail,
          type: "danger",
        });
      }
    },
    //定时器
    timerTime() {
      this.timerNum = 120;
      this.isShowCode = false;
      this.timerItem = setInterval(() => {
        this.timerNum--;
        if (this.timerNum < 1) {
          clearInterval(this.timerItem);
          this.isShowCode = true;
        }
        console.log(this.timerNum);
      }, 1000);
    },
    //下一步
    next() {
      if (!this.checked) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: this.$t.auth.tick + this.$t.auth.rulesAndProtocols,
        });
        return false;
      }
      const { code, email } = this.auth;
      const reg = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(email) || code === "") {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
          titleMsg: this.$t.auth.verificationCodeErroe,
          type: "danger",
        });
        return false;
      }
      let param = {
        Email: email,
        AuthCode: code,
      };
      this.$api.system
        .checkEmailCode(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: res.message,
            type: "success",
          });
          setTimeout(() => {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.routerPush,
              {
                isParams: true,
                path: "auth/regInfo",
                name: "RegInfo",
                query: [
                  {
                    name: "AuthCode",
                    value: param.AuthCode,
                  },
                  {
                    name: "Email",
                    value: param.Email,
                  },
                  {
                    name: "InviteCode",
                    value: this.inviteCode,
                  },
                ],
              }
            );
          }, 1000);
        })
        .catch((err) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: err.message,
            type: "danger",
          });
        });
    },
  },
  destroyed() {
    clearInterval(this.timerItem);
    this.timerItem = null;
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.registerBox {
  position: relative;
  width: 100%;
  .loginTabs {
    ::v-deep .van-tabs__nav {
      background: rgba(0, 0, 0, 0.1);
    }
    ::v-deep .van-tabs__wrap {
      width: 60%;
      border-radius: 10rem;
      margin: auto;
    }
    ::v-deep .van-tab {
      color: #fff;
      font-weight: bold;
    }
    ::v-deep .van-tab--active {
      border-radius: 10rem;
      background: #fff;
      color: #43cd9a;
    }
    ::v-deep .van-hairline--top-bottom::after {
      display: none;
    }
    ::v-deep .van-tabs__line {
      display: none;
    }
    ::v-deep .van-tab__text {
      font-size: 1rem;
      line-height: 2;
      overflow: visible;
    }
  }
  //表单
  .formBox {
    width: 85%;
    position: relative;
    margin: 0 auto;
    margin-top: 2rem;
    .formTitle {
      width: 190px;
      border-radius: 50px;
      background-color: #ffffff;
      color: #6dc093;
      height: 24px;
      font-size: 26px;
      text-align: center;
      height: 70px;
      line-height: 70px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
    }
    .inputBox {
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;
      width: 100%;
      @include publicFlex;
      .username,
      .password {
        background: none;
        outline: none;
        border: none;
        -webkit-appearance: none; /*去除系统默认的样式*/
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
        color: #f3faf8;
        font-size: 1rem;
        width: 100%;
        height: 50px;
        line-height: normal;
        border: 0.1rem solid #b9e1da;
        border-radius: 100px;
        padding: 0 1.5rem;
        box-sizing: border-box;
        &::placeholder {
          color: #f3faf8;
          font-size: 1rem;
        }
        &::-webkit-input-placeholder {
          color: #f3faf8;
          font-size: 1rem;
        }
      }
      .getCode,
      .codeInfo {
        outline: none;
        border: 0;
        height: 50px;
        width: 185px;
        background-color: #ffffff;
        color: #6dc093;
        font-size: 0.8rem;
        text-align: center;
        border-radius: 80px;
        overflow: hidden;
        margin-left: 5%;
      }
      .codeInfo {
        font-size: 1rem;
        line-height: 50px;
        background-color: #dcdcdc;
        color: #ffffff;
      }
    }
    .next {
      width: 100%;
      border-radius: 50px;
      line-height: 50px;
      text-align: center;
      color: #43cd9a;
      font-size: 1rem;
      font-weight: bold;
      background-color: #f3faf8;
      margin-top: 20px;
    }
    .InvitationCode {
      margin-top: 20px;
      font-size: 24px;
      width: 100%;
      height: 96px;
      line-height: 96px;
      border: 1px solid #b9e1da;
      border-radius: 100px;
      padding: 0 47px;
      box-sizing: border-box;
      color: #000000;
      color: rgba(0, 0, 0, 1);
      font-size: 30px;
      font-family: Helvetica;
      font-weight: 400;
      opacity: 0.3;
    }
    .classHome {
      text-decoration: none;
      text-align: center;
      margin-top: 25px;
      &:link,
      :visited,
      :hover,
      :active {
        text-decoration: none;
      }
      .termsTitle {
        margin-top: 25px;
        font-size: 14px;
        color: #ffffff;
        .van-checkbox {
          width: 10%;
          display: inline-block;
          vertical-align: middle;
          ::v-deep .van-checkbox__label {
            color: #ffffff;
          }
        }
        .termsText {
          color: #e5e5e5;
          width: 90%;
          display: inline-block;
          text-align: left;
          vertical-align: middle;
        }
      }
    }
    .forget {
      margin-top: 25px;
      color: #ffffff;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
